import React, { useState } from 'react';
import '../stylesheets/styles.css';
import { createFormCaptureData } from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import ReactModal from 'react-modal';

function ContactModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleNoteChange = (e) => setNote(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        // Handle form submission logic here
        const input = {
            name,
            email,
            note
        };
        const response = await API.graphql(graphqlOperation(createFormCaptureData, { input: input}));
        setSuccess(true);
        setTimeout(() => {
            isOpen(false);
            setLoading(false);
        }, 5000);
    } catch (e) {
        alert(e);
        setLoading(false);
    }
  };
  return (
    <div>
      <button className="contact-button" onClick={() => setIsOpen(true)}>Contact Us</button>
      <ReactModal className='modal-content' isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        {success ? <h3>We got your request! You should hear back from us soon.</h3> : 
        <>
            <h2 className='modal-header'>Contact Us</h2>
            <form className="modal-form" onSubmit={handleSubmit}>
                <label>Name</label>
                <input type="text" value={name} onChange={handleNameChange} />
                <label>Email</label>
                <input type="email" value={email} onChange={handleEmailChange} />
                <label>Note (optional)</label>
                <input type="note" value={note} onChange={handleNoteChange} />
                <button type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
            </form>
        </>}
      </ReactModal>
    </div>
  );
}
export default ContactModal;