import React from 'react';
import './stylesheets/styles.css';
import logo from './assets/stackabl_logo.ico.png';
import ContactModal from './components/ContactModal';
const backgroundImage = require('./assets/background24k.png');

function App() {
  return (
    <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh' }}>
      <div className="logo-container" style={{ paddingLeft: '30px', padding: '10px'}}>
        <img src={logo} alt="Logo" class="logo"/>
        <h1 class="company-name">stackabl designs</h1>
      </div>
      <div className="text-container">
        <div className="block1">Let's Build Together</div>
        <div className="block2">Mobile applications, web applications, and dashboards</div>
        <div className="block3">Connect with your customers, build out your business, and drive insights into your own data. Let's build custom software solutions for you.</div>
        <ContactModal />
      </div>
    </div>
  );
}
export default App;
