/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFormCaptureData = /* GraphQL */ `
  mutation CreateFormCaptureData(
    $input: CreateFormCaptureDataInput!
    $condition: ModelFormCaptureDataConditionInput
  ) {
    createFormCaptureData(input: $input, condition: $condition) {
      name
      email
      note
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateFormCaptureData = /* GraphQL */ `
  mutation UpdateFormCaptureData(
    $input: UpdateFormCaptureDataInput!
    $condition: ModelFormCaptureDataConditionInput
  ) {
    updateFormCaptureData(input: $input, condition: $condition) {
      name
      email
      note
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteFormCaptureData = /* GraphQL */ `
  mutation DeleteFormCaptureData(
    $input: DeleteFormCaptureDataInput!
    $condition: ModelFormCaptureDataConditionInput
  ) {
    deleteFormCaptureData(input: $input, condition: $condition) {
      name
      email
      note
      id
      createdAt
      updatedAt
    }
  }
`;
